import { gsap } from 'gsap';
import * as THREE from 'three';
import handleCameraAnimation from './handleCameraAnimation';

const handleAreaInteraction = (
    event, 
    raycaster, mouse, cameraRef, 
    areas, 
    setActiveProduct, 
    floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
    targetPositionCamera, 
    lookAtPosition, 
    isMobile, 
    animating, 
    setMerchActive, 
    setAreaActive,
    setHoveredArea,
    setToggleFAQ,
    onCameraAnimationComplete
) => {
    if (event) {
        event.stopPropagation();
    }

    // Get the 3D objects associated with the areas
    const areaObjects = areas.map(area => area.target.current);

    // Filter out any undefined objects
    const validAreaObjects = areaObjects.filter(object => object !== undefined);

    gsap.killTweensOf('.popup-container');
    gsap.killTweensOf(bookCoverMesh.rotation);
    gsap.killTweensOf(bookCoverMesh.position);
    gsap.killTweensOf(bookEndMesh.position);
    gsap.killTweensOf(floorLogoMesh.position);
    gsap.to(bookCoverMesh.rotation, { duration: 0.5, y: 0, ease: 'circ.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookEndMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(bookEndMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(floorLogoMesh.position, { duration: 0.8, y: 0.229, ease: 'expo.inOut' });
    gsap.to(closeBookMesh.position, { duration: 0.8, y: -38, ease: 'expo.inOut' });

    
    if (validAreaObjects.length > 0) {
        raycaster.setFromCamera(mouse, cameraRef.current);
        const intersects = raycaster.intersectObjects(validAreaObjects);

        if (intersects.length > 0) {
            // Handle hover
            const intersectedArea = areas.find(area => area.target.current === intersects[0].object);
            if (intersectedArea) {
                if (isMobile) {
                    setHoveredArea(intersectedArea.id);
                }
                setActiveProduct(intersectedArea.id);
                if (intersectedArea.id === 'deals') {
                    gsap.to('.popup-container', { duration: 1, x: 0, y:0, ease: 'expo.inOut', delay: 0.25 })
                } else {
                    if (isMobile) {
                        gsap.to('.popup-container', { duration: 0.5, y: 700 }); 
                    } else {
                        gsap.to('.popup-container', { duration: 0.5, x: -700 });
                    }
                }

                // Use handleCameraAnimation for consistent camera behavior
                const areaWithAdjustedPosition = {
                    ...intersectedArea,
                    position: [
                        intersectedArea.position[0],
                        intersectedArea.position[1] + (intersectedArea.label === 'AREA' ? -10 : (intersectedArea.label === 'GEAR' ? -6 : 0)),
                        intersectedArea.position[2]
                    ]
                };

                // Set animation state for OrbitControls management
                animating.current = true;

                // Create a camera animation that matches the product interaction style
                const targetPosition = new THREE.Vector3(
                    areaWithAdjustedPosition.camera[0],
                    areaWithAdjustedPosition.camera[1],
                    areaWithAdjustedPosition.camera[2]
                );
                targetPositionCamera.current.copy(targetPosition);
                lookAtPosition.current.copy(new THREE.Vector3(...areaWithAdjustedPosition.position));

                gsap.to(cameraRef.current.position, {
                    duration: 1,
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                    onUpdate: () => {
                        if (cameraRef.current) {
                            cameraRef.current.updateProjectionMatrix();
                            targetPositionCamera.current.copy(cameraRef.current.position);
                        }
                    },
                    onComplete: () => {
                        // Re-enable controls after animation completes on mobile
                        // if (isMobile) {
                        //     animating.current = false;
                        // }
                        // Call the callback to set cameraAnimating to false
                        if (onCameraAnimationComplete) {
                            onCameraAnimationComplete();
                        }
                    }
                });
            }
        } else {
            setActiveProduct(null);
        }
        
        if (!isMobile) { 
            animating.current = true;
        }
    }

    setMerchActive(false);
    setAreaActive(false);
    setToggleFAQ(false);
    setToggleFAQ(false);
};

export default handleAreaInteraction;
