import { gsap } from 'gsap';
import * as THREE from 'three';

const handleCameraAnimation = (
    productId,
    products,
    targetPositionCamera,
    lookAtPosition,
    cameraRef,
    isMobile,
    animating,
    setCameraAnimationCompleted
) => {
    // Validate required parameters
    if (!productId || !products || !targetPositionCamera || !lookAtPosition || !cameraRef || !animating) {
        console.log('Missing required parameters for camera animation');
        return;
    }

    // Ensure we have a valid camera reference
    if (!cameraRef.current) {
        console.log('Camera reference not available');
        return;
    }

    // Find the product
    const activeProduct = products.find(product => product.id === productId);
    
    // Validate product and its properties
    const hasValidCameraPositions = activeProduct && 
        activeProduct.camera && 
        activeProduct.position && 
        Array.isArray(activeProduct.camera) && 
        Array.isArray(activeProduct.position) &&
        activeProduct.camera.length === 3 && 
        activeProduct.position.length === 3 &&
        activeProduct.camera.every(coord => typeof coord === 'number') &&
        activeProduct.position.every(coord => typeof coord === 'number');

    if (hasValidCameraPositions) {
        try {
            // Set animation state to disable OrbitControls temporarily
            animating.current = true;
            setCameraAnimationCompleted(false);

            // Create vectors for camera movement
            const targetCameraPos = new THREE.Vector3(...activeProduct.camera);
            const targetLookAtPos = new THREE.Vector3(...activeProduct.position);

            // Update camera target positions
            targetPositionCamera.current.copy(targetCameraPos);
            lookAtPosition.current.copy(targetLookAtPos);
            
            // Animate camera movement
            gsap.to(cameraRef.current.position, {
                duration: 1,
                x: targetPositionCamera.current.x,
                y: targetPositionCamera.current.y,
                z: targetPositionCamera.current.z,
                onUpdate: () => {
                    if (cameraRef.current) {
                        cameraRef.current.updateProjectionMatrix();
                        targetPositionCamera.current.copy(cameraRef.current.position);
                    }
                },
                onComplete: () => {
                    // Re-enable controls after animation completes on mobile
                        setCameraAnimationCompleted(true);
                    // if (isMobile) {
                    //     animating.current = false;
                    // }
                }
            });
        } catch (error) {
            console.log('Error during camera animation:', error);
            animating.current = false;
        }
    } else {
        // If no valid camera positions, just update the state without animation
        console.log('Product does not have valid camera positions:', productId);
        // if (isMobile) {
        //     animating.current = false;
        // }
    }
};

export default handleCameraAnimation;
