import { gsap } from 'gsap';
import * as THREE from 'three';

const handleCloseBookInteraction = (
    event, 
    floorLogoMesh,
    bookCoverMesh,
    bookEndMesh,
    closeBookMesh,
    targetPositionCamera,
    lookAtPosition,
    cameraRef,
    isMobile,
    animating
    ) => {
    if (event) {
        event.stopPropagation();
    }

    gsap.killTweensOf('.popup-container');
    gsap.killTweensOf(bookCoverMesh.rotation);
    gsap.killTweensOf(bookCoverMesh.position);
    gsap.killTweensOf(bookEndMesh.position);
    gsap.killTweensOf(floorLogoMesh.position);
    gsap.killTweensOf(closeBookMesh.position);
    gsap.to(bookCoverMesh.rotation, { duration: 0.5, y: 0, ease: 'circ.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookEndMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(bookEndMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(floorLogoMesh.position, { duration: 0.8, y: 0.229, ease: 'expo.inOut' });
    gsap.to(closeBookMesh.position, { duration: 0.8, y: -38, ease: 'expo.inOut' });
    gsap.to('.popup-container', { duration: 0, x:-700, y: 0 });

    // Reset camera position
    if (cameraRef && cameraRef.current) {
        // Set animation state to disable OrbitControls temporarily
        if (animating) {
            animating.current = true;
        }

        // Reset to default camera position
        const defaultPosition = new THREE.Vector3(-20, isMobile ? 40 : -9, isMobile ? 70 : 85);
        const defaultLookAt = new THREE.Vector3(0, -19, 0);

        // Update camera target positions
        if (targetPositionCamera) {
            targetPositionCamera.current.copy(defaultPosition);
        }
        if (lookAtPosition) {
            lookAtPosition.current.copy(defaultLookAt);
        }

        // Animate camera movement
        gsap.to(cameraRef.current.position, {
            duration: 1,
            x: defaultPosition.x,
            y: defaultPosition.y,
            z: defaultPosition.z,
            onUpdate: () => {
                if (cameraRef.current) {
                    cameraRef.current.updateProjectionMatrix();
                    if (targetPositionCamera) {
                        targetPositionCamera.current.copy(cameraRef.current.position);
                    }
                }
            },
            onComplete: () => {
                // Re-enable controls after animation completes on mobile
    
                if (isMobile && animating) {
                    animating.current = false;
                }
            }
        });
    }
};

export default handleCloseBookInteraction;
