import React, { Suspense} from 'react'
import { AppContext } from './context/AppContext'
import { useThree, useFrame } from '@react-three/fiber'
import { Plane } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei'
import { Environment, Sky } from '@react-three/drei'
import PlatformObject from './components/PlatformObject.jsx'
import { VideoMaterial, FallbackMaterial } from './components/VideoComponents.jsx';

export default function Experience() {
    const { isFullscreen, isMobile, animating, cameraRef } = React.useContext(AppContext)
    const { camera, gl } = useThree()

    // Set camera reference for use outside of canvas
    React.useEffect(() => {
        if (camera && cameraRef) {
            cameraRef.current = camera;
        }
    }, [camera, cameraRef]);

    return (
        <>
            <OrbitControls
                camera={camera}
                enableDamping
                dampingFactor={0.1}
                enableZoom={isMobile}
                maxDistance={82}
                rotateSpeed={isMobile? '0.6' : '0.1'}
                enabled={isFullscreen && (!animating.current || !isMobile)} // Disable controls during animations on mobile
                enablePan={isMobile} 
                minPolarAngle={0}
                maxPolarAngle={isMobile ? Math.PI / 1.7 : Math.PI}
            />
            <Environment preset="sunset" />
            <ambientLight
                intensity={1.4} /> 

            <Sky distance={450000} turbidity={10} azimuth={180} />
            <PlatformObject />
            <Plane args={[2.05, 1.265]}
                position={[-15.01, -21.37, 39.89]}
                rotation={[0.05,-0.52,0.02]}>
                    <Suspense fallback={<FallbackMaterial />}>
                        <VideoMaterial />
                    </Suspense>
            </Plane>
        </>
    )
}
