import React, { useState, useRef} from 'react';
import * as THREE from 'three'
import useMediaQuery from '../hooks/useMediaQuery';

export const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const animating = useRef(false);
  const mobileAnimating = useRef(false);
  const lookAtPosition = useRef(new THREE.Vector3(0, -25, 0))
  const isMobile = useMediaQuery('(max-width: 768px)');
  const targetPositionCamera = useRef(new THREE.Vector3(-20, isMobile ? 40 : -9, isMobile ? 70 : 85));
  const [showShoppingList, setShowShoppingList] = useState(true);
  const [hoveredProduct, setHoveredProduct] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const [showShopifyPopup, setShowPopup] = useState(false);
  const [merchActive, setMerchActive] = useState(false);
  const [hoveredArea, setHoveredArea] = useState(null);
  const [areaActive, setAreaActive] = useState(false);
  const videoRef = useRef();
  const [loadVideo, setLoadVideo] = useState(false);
  const floorLogoAnimationRef = useRef();
  const floorLogoMesh = floorLogoAnimationRef.current
  const bookCoverAnimationRef = useRef();
  const bookCoverMesh = bookCoverAnimationRef.current
  const bookEndAnimationRef = useRef();
  const bookEndMesh = bookEndAnimationRef.current;
  const closeBookRef = useRef();
  const closeBookMesh = closeBookRef.current;
  const [toggleFAQ, setToggleFAQ] = useState(false);
  const cameraRef = useRef(null);
  const [cameraAnimationCompleted, setCameraAnimationCompleted] = useState(false);

  const products = [

    
    {
      id: 'holiday',
      title: 'SONIC® Holiday Shop',
      label: 'GIFT',
      desc: 'Gifts for the people in your life that may be a little too obsessed with SONIC®.',
      size: [1, 1, 1],
      iconPath: '/merch/holiday-icon.png',
      imagePath: '/merch/holiday-group.png',
      position: [-19.5, -24.5, 11.7],
      camera: [-13.5, -20, 26.5],
      target: React.createRef()
    },
    {
      shopifyId: '8694558130420',
      id: 'fanny-pack',
      title: 'Fry Fanny Pack',
      label: 'GEAR',
      desc: 'Keep your friends close and your fries closer.',
      size: [1, 1, 1],
      iconPath: '/merch/fanny-pack-icon.png',
      imagePath: '/merch/fanny-pack.jpg',
      position: [13.75, -23.75, 36.3],
      camera: [8, -22, 32],
      target: React.createRef()
    },
    {
      shopifyId: '8694557311220',
      id: 'support-hat',
      title: 'Tot Farmer Hat',
      label: 'GEAR',
      desc: 'Warning: Once you get one silly hat, you need any and all silly hats.',
      size: [1, 1, 1],
      imagePath: '/merch/hat-support.jpg',
      position: [17.1, -23.5, 35],
      camera: [14, -23, 32],
      target: React.createRef()
    },
    {
      shopifyId: '8694541746420',
      id: 't-shirt',
      label: 'GEAR',
      title: 'Corn Dog T-Shirt',
      desc: 'Keys? Check. Wallet? Check. Corn Dog? Check.',
      size: [1, 1, 1],
      imagePath: '/merch/t-shirt.jpg',
      position: [22, -23.75, 34],
      camera: [17.7, -22, 34],
      target: React.createRef()
    },
    {
      shopifyId: '8694555705588',
      id: 'livefree-hat',
      title: 'Live Free Hat',
      label: 'GEAR',
      desc: 'Why yes, you do need another hat.',
      size: [1, 1, 1],
      imagePath: '/merch/hat-livefree.jpg',
      position: [26.75, -23.75, 31],
      camera: [22.5, -22.5, 32],
      target: React.createRef()
    },
    {
      shopifyId: '8694548857076',
      id: 'tot-bag',
      title: 'The Tot Bag',
      label: 'GEAR',
      desc: 'Carries approximately 2,268 Tots. Tag @sonicdrivein if you check our math.',
      size: [2.2, 2, 2],
      imagePath: '/merch/tot-bag.jpg',
      position: [31.3, -23.25, 27],
      camera: [24.55, -22.5, 30],
      target: React.createRef()
    },
    {
      shopifyId: '8899006595316',
      id: 'ice-maker',
      title: '"SONIC®" Ice Maker',
      label: 'GOODS',
      desc: 'First we discovered fire. Then, we discovered something far greater: SONIC Ice®.',
      size: [1, 1, 2],
      imagePath: '/merch/ice-maker.jpg',
      position: [17.95, -23.1, -15.9],
      camera: [15.5, -23.5, -11.5],
      target: React.createRef()
    },
    {
      shopifyId: '8694539878644',
      id: 'car-decals',
      title: 'SONIC® Fam Car Decals',
      label: 'GARAGE',
      desc: 'You\'re about to have the coolest car in the school pick-up line.',
      size: [3, 2, 3],
      imagePath: '/merch/car-decals.jpg',
      position: [-6, -21.5, -35.15],
      camera: [-2, -22, -43],
      target: React.createRef()
    },
    {
      shopifyId: '8694559670516',
      id: 'rubber-duck',
      title: 'Carhop Rubber Ducky',
      label: 'GARAGE',
      desc: 'Is it carpooling if a duck\'s up on the dash? Asking for a friend.',
      size: [5, 3, 5],
      imagePath: '/merch/rubber-ducky.jpg',
      position: [-16, -1.5, -11],
      camera: [-13, 2, 2],
      target: React.createRef()
    },
    // {
    //   shopifyId: '8694551019764',
    //   id: 'one-wheel',
    //   title: 'Uniskate Electric Board',
    //   label: 'GARAGE',
    //   desc: 'Technically… This is a unicycle. Think about it.',
    //   size: [4, 2, 4],
    //   imagePath: '/merch/one-wheel.jpg',
    //   position: [7, -24.5, -26.5],
    //   camera: [1, -24.25, -25],
    //   target: React.createRef()
    // },
    // {
    //   shopifyId: '8792944345332',
    //   id: 'deck',
    //   title: 'XGames x SONIC® <br/><em>LIVE FREE</em> <br/>Skateboard Deck',
    //   label: 'GARAGE',
    //   desc: 'If someone doesn\'t do a Breakfast Burger with this on their board, we\'re rioting.',
    //   imagePath: '/static/xgames-deck.jpg',
    //   size: [2, 2, 2],
    //   position: [-15, -25, -15.0],
    //   camera: [-7, -23, -5],
    //   target: React.createRef()
    // },
    {
      id: 'faqs',
      title: 'FAQS',
      label: 'MORE',
      imagePath: '/merch/icon-faq.png',
      position: [-19, -21.5, 37],
      camera: [-22, -21, 50],
      target: React.createRef()
    },
    {
      id: 'deals',
      title: 'DEALS',
      label: 'MORE',
      imagePath: '/merch/icon-deals.png',
      position: [-41, -23, 8],
      camera: [-37.5, -22.5, 6.5],
      target: React.createRef()
    },
  ];

  const holiday = [
    {
      shopifyId: '8899006595316',
      id: 'holiday-ice',
      title: '"SONIC®” Ice Maker',
      label: 'LTO',
      desc: 'First we discovered fire. Then, we discovered something far greater: SONIC Ice®.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249148637428',
      id: 'holiday-ornament',
      title: 'SONIC® Collectible Ornament',
      label: 'LTO',
      desc: 'We take holiday cheer quite literally.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249157550324',
      id: 'holiday-mug',
      title: 'Festive Glass Mug',
      label: 'LTO',
      desc: 'Time to cozy up with a hot cocoa … or Limeade.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249159385332',
      id: 'holiday-sweatshirt',
      title: 'Crew Neck Sweatshirt',
      label: 'LTO',
      desc: 'Passion for fashion. And snacks.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249151226100',
      id: 'holiday-socks',
      title: 'Footlong Sock Bundle (3 Pairs)',
      label: 'LTO',
      desc: 'Keep your dogs warm.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249155256564',
      id: 'holiday-burger-toy',
      title: 'Bacon Cheeseburger Dog Toy',
      label: 'LTO',
      desc: 'So fetch.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249156010228',
      id: 'holiday-shake-toy',
      title: 'Strawberry Shake Dog Toy',
      label: 'LTO',
      desc: 'So fetch.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249156698356',
      id: 'holiday-footlong-toy',
      title: 'Footlong Coney Dog Toy',
      label: 'LTO',
      desc: 'So fetch.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249153880308',
      id: 'holiday-toy-pack',
      title: 'Dog Toys (Pack of 3)',
      label: 'LTO',
      desc: 'So fetch.',
      imagePath: '/merch/holiday-group.png'
    },
    {
      shopifyId: '8249152864500',
      id: 'holiday-dog-hoodie',
      title: 'Dog Lightweight Hoodie',
      label: 'LTO',
      desc: 'For your favorite four-legged friend.',
      imagePath: '/merch/holiday-group.png'
    },
  ]
  const xgames = [
    {
      id: 'deck',
      title: 'Skate Deck',
      label: 'LTO',
      desc: 'If someone doesn\'t do a Breakfast Burger with this on their board, we\'re rioting.',
      imagePath: '/static/xgames-deck.jpg'
    },
    {
      id: 'tumbler',
      title: 'Tumbler',
      label: 'LTO',
      desc: 'Chuggin\' from one of these things is an extreme sport. ',
      imagePath: '/static/xgames-tumbler.jpg'
    },
    {
      id: 'shirt',
      title: 'Shirt',
      label: 'LTO',
      desc: 'You got that steez.',
      imagePath: '/static/xgames-shirt.jpg'
    }
  ]
  const areas = [
    {
      id: 'deals',
      label: 'AREA',
      desc: 'Save some bacon. And add some too.',
      cta: 'SEE ALL DEALS',
      size: [15, 5, 2],
      imagePath: '/merch/deals-desk.jpg',
      url: 'https://www.sonicdrivein.com/deals',
      ariaLabel: 'Click to access exclusive deals on the Sonic Drive-in website.',
      position: [-41, -13, 8],
      camera: [-37.5, -22.5, 6.5],
      target: React.createRef()
    },
    {
      id: 'gear',
      title: 'GEAR',
      label: 'GEAR',
      desc: 'Fits fresher than a crispy Ocean Water®.',
      size: [13.5, 4, 2],
      position: [22.5, -17.5, 34],
      camera: [8, -21.5, 33],
      target: React.createRef()
    },
    {
      id: 'goods',
      title: 'GOODS',
      label: 'AREA',
      desc: 'Make your SONIC® house a SONIC® home.',
      size: [15, 5, 2],
      position: [20, -10.5, -14],
      camera: [19, -14, 4],
      target: React.createRef()
    },
    {
      id: 'garage',
      title: 'GARAGE',
      label: 'AREA',
      desc: 'Ready to roll.',
      size: [15, 5, 2],
      position: [-8, -2, -17],
      camera: [3, 4, 10],
      target: React.createRef()
    },
  ]
  const surprises = [
    
    {
      id: 'deals',
      label: 'KIOSK',
      title: 'Save some bacon. And add some too.',
      cta: 'SEE ALL DEALS',
      size: [3, 9, 9],
      imagePath: '/merch/deals-desk.jpg',
      alt: 'Icon for the Sonic app',
      url: 'https://www.sonicdrivein.com/deals',
      urlTarget: '_blank',
      ariaLabel: 'Click to access exclusive deals on the Sonic Drive-in website.',
      position: [-41, -23, 8],
      camera: [-37.5, -22.5, 6.5],
      target: React.createRef()
    },
    {
      id: 'faqs',
      title: 'FAQS',
      label: 'FAQs',
      desc: 'Find the answers to the most commonly asked questions.',
      size: [1.5, 6, 6],
      position: [-18, -21.5, 38.4],
      camera: [-22, -21, 50],
      target: React.createRef()
    },
    {
      id: 'tv',
      size: [1.2, 6, 6],
      position: [-15.2, -21.5, 40],
      camera: [-16.5, -21.5, 42.5],
      target: React.createRef()
    },
    {
      id: 'book',
      label: 'BOOK',
      size: [6, 6, 6],
      position: [-1, -28, 1],
      camera: [40, -15, 15],
      target: React.createRef()
    },
    // {
    //   id: 'trip',
    //   label: 'TRIP',
    //   size: [1.2, 6, 6],
    //   title: 'Fun is on the Menu Sweepstakes',
    //   cta: 'HAVE SOME FUN',
    //   imagePath: '/merch/trip.jpg',
    //   alt: 'Icon for the Sonic app',
    //   url: '/fun99',
    //   urlTarget: '_self',
    //   ariaLabel: 'Click to access exclusive deals on the Sonic Drive-in website.',
    //   position: [-7.9, -24.3, 31.3],
    //   camera: [-3, -22, 40],
    //   target: React.createRef()
    // },
  ]

  const buttonRefs = products.map(React.createRef);

  return (
    <AppContext.Provider value={{
      isFullscreen, setIsFullscreen,
      animating,
      isMobile,
      lookAtPosition,
      targetPositionCamera,
      showShoppingList, setShowShoppingList,
      activeProduct, setActiveProduct,
      hoveredProduct, setHoveredProduct,
      showShopifyPopup, setShowPopup,
      hoveredArea, setHoveredArea,
      merchActive, setMerchActive,
      areaActive, setAreaActive,
      products,
      holiday,
      areas,
      surprises,
      buttonRefs,
      videoRef,
      loadVideo, setLoadVideo,
      floorLogoAnimationRef, floorLogoMesh,
      bookCoverAnimationRef, bookCoverMesh,
      bookEndAnimationRef, bookEndMesh,
      closeBookRef, closeBookMesh,
      toggleFAQ, setToggleFAQ,
      cameraRef,
      cameraAnimationCompleted, setCameraAnimationCompleted
    }}>
      {children}
    </AppContext.Provider>
  );
};
