import { gsap } from 'gsap';
import * as THREE from 'three';
import handleCameraAnimation from './handleCameraAnimation';

const handleSurpriseInteraction = (
    event, 
    raycaster, mouse, cameraRef, 
    surprises,
    activeProduct, setActiveProduct,
    videoRef,
    setLoadVideo,
    floorLogoMesh,
    bookCoverMesh,
    bookEndMesh,
    closeBookMesh,
    targetPositionCamera,
    lookAtPosition,
    isMobile,
    animating,
    setMerchActive,
    setAreaActive,
    onCameraAnimationComplete
) => {
    if (event) {
        event.stopPropagation();
    }

    // Get the 3D objects associated with the surprises
    const surpriseObjects = surprises.map(Surprise => Surprise.target.current);

    // Filter out any undefined objects
    const validSurpriseObjects = surpriseObjects.filter(object => object !== undefined);
    
    gsap.killTweensOf('.popup-container');
    gsap.killTweensOf(bookCoverMesh.rotation);
    gsap.killTweensOf(bookCoverMesh.position);
    gsap.killTweensOf(bookEndMesh.position);
    gsap.killTweensOf(floorLogoMesh.position);
    gsap.killTweensOf(closeBookMesh.position);
    gsap.to(bookCoverMesh.rotation, { duration: 0.5, y: 0, ease: 'circ.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookEndMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
    gsap.to(bookCoverMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(bookEndMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
    gsap.to(floorLogoMesh.position, { duration: 0.8, y: 0.229, ease: 'expo.inOut' });
    gsap.to(closeBookMesh.position, { duration: 0.8, y: -38, ease: 'expo.inOut' });

    if (validSurpriseObjects.length > 0) {
        raycaster.setFromCamera(mouse, cameraRef.current);
        const intersects = raycaster.intersectObjects(validSurpriseObjects);

        if (intersects.length > 0) {
            // Handle inner click
            const intersectedSurprise = surprises.find(Surprise => Surprise.target.current === intersects[0].object);
            if (intersectedSurprise) {
                if (activeProduct === 'kiosk' || activeProduct === 'deals') {
                    // Open the URL in a new tab
                    window.open(intersectedSurprise.url, '_blank');
                }
                if (activeProduct === 'trip') {
                    // Open the URL
                    window.open(intersectedSurprise.url);
                }
            }
            setActiveProduct(intersectedSurprise.id);
            
            if (intersectedSurprise.id === 'tv') {
                if (isMobile) {
                    gsap.to('.popup-container', { duration: 0, y: 700 }); 
                } else {
                    gsap.to('.popup-container', { duration: 0, x: -700 });
                }
                if (videoRef.current) {
                    videoRef.current.play();
                }
                setLoadVideo(true)
            }
            else if (intersectedSurprise.id === 'book') {
                if (isMobile) {
                    gsap.to('.popup-container', { duration: 0, y: 700 }); 
                } else {
                    gsap.to('.popup-container', { duration: 0, x: -700 });
                }
                gsap.to(floorLogoMesh.position, { duration: 3, y: 7.5, ease: 'expo.inOut' });
                gsap.to(bookCoverMesh.position, { duration: 5, y: 3.5, delay: 1.25, ease: 'expo.out' });
                gsap.to(bookEndMesh.position, { duration: 5, y: 3.5, delay: 1.25, ease: 'expo.out' });
                gsap.to(bookCoverMesh.rotation, { duration: 3, y: -2.5, delay: 2, ease: 'circ.inOut' });
                gsap.to(bookCoverMesh.position, { duration: 3.2, z: 0.35, delay: 1.8, ease: 'expo.inOut' });
                gsap.to(bookEndMesh.position, { duration: 3, z: 0.5, delay: 2, ease: 'expo.inOut' });
                gsap.to(closeBookMesh.position, { duration: 3, y: -11, ease: 'expo.inOut' });
            }
            else {
                gsap.to('.popup-container', { duration: 1, x:0, y: 0, ease: 'expo.inOut', delay: 0.25 })
            }
            if (intersectedSurprise) {
                // Use handleCameraAnimation for consistent camera behavior
                const surpriseWithAdjustedPosition = {
                    ...intersectedSurprise,
                    position: [
                        intersectedSurprise.position[0],
                        intersectedSurprise.position[1] + (intersectedSurprise.label === 'BOOK' ? 15 : 0),
                        intersectedSurprise.position[2]
                    ]
                };

                // Set animation state for OrbitControls management
                animating.current = true;

                // Create a camera animation that matches the product interaction style
                const targetPosition = new THREE.Vector3(
                    surpriseWithAdjustedPosition.camera[0],
                    surpriseWithAdjustedPosition.camera[1],
                    surpriseWithAdjustedPosition.camera[2]
                );
                targetPositionCamera.current.copy(targetPosition);
                lookAtPosition.current.copy(new THREE.Vector3(...surpriseWithAdjustedPosition.position));

                gsap.to(cameraRef.current.position, {
                    duration: 1,
                    x: targetPosition.x,
                    y: targetPosition.y,
                    z: targetPosition.z,
                    onUpdate: () => {
                        if (cameraRef.current) {
                            cameraRef.current.updateProjectionMatrix();
                            targetPositionCamera.current.copy(cameraRef.current.position);
                        }
                    },
                    onComplete: () => {
                        // Re-enable controls after animation completes on mobile
                        // if (isMobile) {
                        //     animating.current = false;
                        // }
                        // Call the callback to set cameraAnimating to false
                        if (onCameraAnimationComplete) {
                            onCameraAnimationComplete();
                        }
                    }
                });

            }
        } else {
            setActiveProduct(null);
        }
        
        // PopupOpenState
        if(!isMobile) {animating.current = true}
        setMerchActive(true)
        setAreaActive(false)
    }
};

export default handleSurpriseInteraction;
