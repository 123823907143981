import { gsap } from 'gsap';
import * as THREE from 'three';

const handleInteraction = (
    event, 
    productId, 
    products,
    setActiveProduct, 
    floorLogoMesh, bookCoverMesh, bookEndMesh, closeBookMesh,
    targetPositionCamera, 
    lookAtPosition, 
    cameraRef, 
    isMobile, 
    animating, 
    setMerchActive, 
    setAreaActive,
    setToggleFAQ,
    onCameraAnimationComplete,
    ) => {
    if (event) {
        event.stopPropagation();
    }

    let activeProductId = null;

    if (productId) {
        activeProductId = productId;
        const activeProduct = products.find(product => product.id === activeProductId);
        setActiveProduct(activeProductId);
        gsap.killTweensOf('.popup-container');
        gsap.killTweensOf(bookCoverMesh.rotation);
        gsap.killTweensOf(bookCoverMesh.position);
        gsap.killTweensOf(bookEndMesh.position);
        gsap.killTweensOf(floorLogoMesh.position);
        gsap.to(bookCoverMesh.rotation, { duration: 0.5, y: 0, ease: 'circ.inOut' });
        gsap.to(bookCoverMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
        gsap.to(bookEndMesh.position, { duration: 0.25, z: 1.96439, ease: 'expo.inOut' });
        gsap.to(bookCoverMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
        gsap.to(bookEndMesh.position, { duration: 0.7, y: -2.47672, ease: 'expo.out' });
        gsap.to(floorLogoMesh.position, { duration: 0.8, y: 0.229, ease: 'expo.inOut' });
        gsap.to(closeBookMesh.position, { duration: 0.8, y: -38, ease: 'expo.inOut' });

        if(isMobile) {
            gsap.fromTo('.popup-container', { x:0, y: 700,}, { duration: 1, x:0, y: 0, ease: 'expo.inOut', delay: 0.25 })
        } else {
            gsap.to('.popup-container', { duration: 1, x:0, y:0, ease: 'expo.inOut', delay: 0.25 })
        }

        // Re-enable camera animations for both desktop and mobile
        if (activeProduct && activeProduct.camera && activeProduct.position) {
            targetPositionCamera.current.copy(new THREE.Vector3(...activeProduct.camera));
            lookAtPosition.current.copy(new THREE.Vector3(...activeProduct.position));
            
            // Trigger animation state for OrbitControls management
            animating.current = true;

            gsap.to(cameraRef.current.position, {
                duration: 1,
                x: targetPositionCamera.current.x,
                y: targetPositionCamera.current.y,
                z: targetPositionCamera.current.z,
                onUpdate: () => {
                    if (cameraRef.current) {
                        cameraRef.current.updateProjectionMatrix();
                        targetPositionCamera.current.copy(cameraRef.current.position);
                    }
                },
                onComplete: () => {
                    // Re-enable controls after animation completes on mobile
                    // if (isMobile) {
                    //     animating.current = false;
                    // }
                    // Call the callback to set cameraAnimating to false
                    if (onCameraAnimationComplete) {
                        onCameraAnimationComplete();
                    }
                }
            });
        }
    } else {
        setActiveProduct(null);
    }

    setMerchActive(true);
    setAreaActive(false);
    setToggleFAQ(false);
};

export default handleInteraction;
