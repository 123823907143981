import React from "react";
import { Helmet } from "react-helmet";

// import styles
import "../styles/static/terms.css";
export default function Terms() {
  // This return statement won&apos;t be rendered as the component will redirect immediately
  return (
    <div className="terms terms-container" aria-label="Primary" role="main">
      <Helmet>
        <title>Sonic Iconic Giveaway - Terms & Conditions</title>
        <meta
          property="og:title"
          content="Sonic Iconic Giveaway - Terms & Conditions"
        />
        <meta
          property="og:description"
          content="Get the official rules and how to enter theSonic Iconic Giveaway."
        />
        <meta
          property="og:image"
          content="https://livefreeshopsonic.com/ui/favicon/fun99-og-image.jpg"
        />
      </Helmet>
      <div className="content-container">
        <h1 className="center">Sonic Iconic Giveaway Official Rules:</h1>
        <p className="bold center">
          NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A
          PURCHASE OR PAYMENT WILL NOT INCREASE YOUR CHANCES OF WINNING.
        </p>
        <p>
          <span className="title">Eligibility:</span> The Sonic Iconic Giveaway
          (the
          <span className="bold">&#8220;Giveaway&#8221;</span>) is open only to
          followers of the Sonic Instagram page who are legal
          residents of 50 U.S. and DC (
          <span className="bold">&#8220;eligibility area&#8221;</span>) who are
          18 years of age or older as of date of entry (
          <span className="bold">&#8220;Entrant&#8221;</span>). Void outside the
          eligibility area and where prohibited by law. Employees of Sonic
          Drive-In (&#8220;Sonic&#8221;), Inspire Brands, Inc., and their
          respective subsidiaries, parent, divisions, franchisees, promotional
          partners, agencies, affiliates, advertising and promotion agencies
          (collectively, the
          <span className="bold">&#8220;Released Giveaway Parties&#8221;</span>)
          as well as the immediate family (spouse, parents, siblings and
          children) and household members of each such employee, are not
          eligible to participate. This Giveaway is subject to all applicable
          federal, state, and local laws and regulations and is void where
          prohibited by law. The Giveaway is not affiliated with or sponsored by
          Instagram.
        </p>
        <p>
          <span className="title">Giveaway Period:</span> The Giveaway begins at
          12:00 am Eastern Time (<span className="bold">&#8220;ET&#8221;</span>)
          on December 19, 2024 and ends at 11:59 pm ET on December 23, 2024 (
          <span className="bold">&#8220;Giveaway Period&#8221;</span>). Sonic
          servers are the official time-keeping devices for the Giveaway.
        </p>
        <p>
          <span className="title">How to Enter:</span> Participation
          Requirements: To enter the Giveaway, participants must tag their best
          friend&apos;s name in the Sonic Instagram post by the end
          of the Giveaway Period to be entered for a chance to win the Giveaway
          prizes. There is a limit of one (1) post per person. Any attempt by an
          Entrant to obtain more than one entry by using multiple/different
          Instagram handles, identities, registrations or logins, or any other
          methods will void that Entrant&apos;s entry and that Entrant may be
          disqualified from the Giveaway. Any use of robotic, repetitive,
          automatic, programmed or similar Entry methods or agents will void all
          Entries by that Entrant. In the event of a dispute as to any Entry,
          the authorized account holder of the Instagram handle used to enter
          will be deemed to be the Entrant. The "authorized account holder" is
          the natural person assigned an email address by an Internet access
          provider, online service provider or other organization responsible
          for assigning email addresses for the domain associated with the
          submitted address. Potential winner may be required to show proof of
          being the authorized account holder.
        </p>
        <p>
          <span className="title">Prize:</span> The Prize consists of a box set
          containing 2 medium-sized autographed t-shirts (baby tees), 2
          bedazzled tumblers, and 2 Sonic gift cards (each containing $10 in
          value). A total of five (5) Prizes shall be awarded as part of the
          Giveaway. Approximate Retail Value (&#8220;ARV&#8221;) is $125.
        </p>
        <p>
          <span className="title">Selection of Winner/Odds:</span> Five (5)
          Prize winners will be selected in a random drawing on or about
          December 30, 2024 from among all eligible Entries received. Drawing
          will be conducted by an independent random name picker tool, which
          decisions are final and binding on all matters related to the
          Giveaway. The odds of winning the prize depend on the total number of
          eligible Entries received during the Giveaway Period.
        </p>
        <p>
          <span className="title">Winner Notification:</span> Winners of the
          Giveaway Prizes will be notified by direct message from the Sonic
          Instagram account by December 30, 2024. Winners will have
          two (2) days to respond to the message, provide their address and fill
          out all applicable paperwork including a Declaration of Eligibility
          and Liability Release and except where prohibited, publicity release
          in order to be confirmed as a Prize winner. If a potential Prize
          winner fails to return the completed documents within two (2) days,
          Entrant shall be deemed to be ineligible, and the prize will be
          forfeited and an alternate potential winner will be selected. If an
          Entrant is disqualified for the reasons mentioned above and an
          alternate is selected, the alternate must complete and return the
          required documents in the timeframe specified.
        </p>
        <p>
          <span className="title">General Rules:</span> By participating in the
          Giveaway, Entrant fully and unconditionally agrees to and accepts
          these Official Rules and the decisions of Sonic, whose decisions are
          final and binding in all matters related to the Giveaway. Any normal
          Internet/phone access and data/usage charges imposed by Entrants&apos;
          online/phone service will apply and are Entrants&apos; sole
          responsibility. Sonic is not responsible for any compatibility issues
          with Entrant&apos;s device/browser used for Entry. Entries become the
          property of Sonic upon receipt and will not be acknowledged or
          returned. Entrants associated with potential winning Entries may be
          required to show proof of being the authorized account holder of the
          email address assigned by an Internet access provider, online service
          provider, Internet service provider, or other organization responsible
          for assigning email addresses for the domain associated with the
          submitted address. Entries specifying an invalid, non-working, or
          inactive email address may be disqualified. No information regarding
          Entries, other than as otherwise set forth in these Official Rules
          will be disclosed. Sonic is not responsible for lost, interrupted or
          unavailable network server or other connection; miscommunications;
          failed phone or computer or telephone transmissions; technical
          failure; jumbled, scrambled or misdirected transmissions; late, lost,
          incomplete, delayed, or misdirected Entries; or other error of any
          kind whether human, mechanical, or electronic. CAUTION: Any attempt to
          deliberately damage any web site/device, or undermine the legitimate
          operations of the Giveaway, is a violation of criminal and civil laws.
          Should such an attempt be made, the Sonic reserves the right to seek
          damages from any such participant to the fullest extent permitted by
          law and to disqualify such Entrant from the Giveaway. In the event the
          Giveaway is compromised or impaired in any way for any reason,
          including but not limited to, fraud, virus, bug, unauthorized human
          intervention, outbreak of widespread illness, pandemic, or other
          similar occurrence, civil unrest or any other problem or other causes
          beyond the control of Sonic that corrupts or impairs the
          administration, security, fairness, or proper play of the Giveaway,
          Sonic reserves the right in its sole discretion to suspend or
          terminate the Giveaway and select the Grand Prize winner from among
          all eligible Entries received prior to cancellation. Sonic is not
          responsible for lost, late, misdirected, corrupted, or incomplete
          Entries. Proof of submission is not proof of receipt by Sonic. By
          participating in this Giveaway, Entrants agree to be bound by these
          Official Rules and the decisions of the Sonic. The Sonic reserves the
          right at its sole discretion to disqualify any individual that tampers
          or attempts to tamper with the Entry process or the operation of the
          Giveaway; violates the Official Rules; or acts in an unsportsmanlike
          or disruptive manner, or with intent to annoy, abuse, threaten or
          harass any other person. Any attempt by any person to deliberately
          undermine the legitimate operation of the Giveaway may be a violation
          of criminal and civil law, and, should such an attempt be made, Sonic
          reserves the right to seek damages from any such person to the fullest
          extent permitted by law. Sonic&apos;s failure to enforce any term of
          these Official Rules shall not constitute a waiver of that provision.
          By participation in the Giveaway, Entrants and the Grand Prize winner
          release and hold harmless the Released Giveaway Parties from and
          against any and all liability, claims, or actions of any kind
          whatsoever for injuries, damages, or losses to persons or property
          which may be sustained in connection with submitting an Entry or
          otherwise participating in any aspect of the Giveaway, the receipt,
          ownership or use of any prize awarded, or while preparing for,
          participating in any prize-related activity or any typographical or
          other error in these Official Rules or the announcement or offering of
          any prize.
        </p>
        <p>
          <span className="title">Disputes:</span> Entrant agrees that: (a)
          he/she releases and will defend, indemnify and hold harmless the
          Released Giveaway Parties from and all any and all Claims; (b) Claims
          arising out of or connected with this Giveaway, or any prize awarded
          shall be resolved individually, without resort to any form of class
          action, and solely and exclusively in a federal or state court located
          in Atlanta, GA; (c) Entrant submits to sole and exclusive personal
          jurisdiction to said courts in the State of Georgia for any such
          dispute and irrevocably waives any and all rights to object to such
          jurisdiction; (d) any and all Claims, judgments, and awards shall be
          limited to actual damages of no more than $100, including costs
          associated with entering this Giveaway, but in no event
          attorneys&apos; fees; and (e) under no circumstances will Entrant be
          permitted to obtain awards for and Entrant hereby waives all rights to
          claim punitive, incidental and consequential damages and any other
          damages, other than for actual out-of-pocket expenses, and any and all
          rights to have damages multiplied or otherwise increased. SOME
          JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
          FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO
          YOU. All issues and questions concerning the construction, validity,
          interpretation and enforceability of these Official Rules, or the
          rights and obligations of Entrants or the Released Giveaway Parties in
          connection with this Giveaway shall be governed by, and construed in
          accordance with, the laws of the State of Georgia, without giving
          effect to any choice of law or conflict of law rules of provisions
          (whether of the State of Georgia, the United States, or any other
          jurisdiction), which would cause the application of the laws of any
          jurisdiction other than the State of Georgia.
        </p>
      </div>
    </div>
  );
}
