import React, {useContext} from 'react'
import { gsap } from 'gsap'
import { AppContext } from '../context/AppContext'
export default function ExploreNav() {

  const { 
    isFullscreen, 
    isMobile,
    setIsFullscreen,
    setActiveProduct,
    animating,
    videoRef,
    setLoadVideo
   } = useContext(AppContext)
  
  const handleExploreButtonClick = () => {
    document.body.style.overflowY = 'scroll';

    setIsFullscreen(false)
    animating.current = false
    if (isMobile) {
      gsap.to('.popup-container', { duration: 0, y: 700 }); 
    } else {
        gsap.to('.popup-container', { duration: 0, x: -700 });
    }
    setActiveProduct(null)

    if (videoRef.current) {
      videoRef.current.pause();
    }
    setLoadVideo(false);

    // Delay the scrolling until after the overflowY property has been set
    setTimeout(() => {
      window.scrollTo({
        top: 100,
        behavior: 'smooth'
      });
    }, 0);

  }

  return (

    <>
      {/* <div id="explore-list"></div> */}
      <div className="explore-navbar">
        <button aria-label="Exit the 3D Experience" className={!isFullscreen ? 'nav-item disabled' : 'nav-item'}
          onClick={() => handleExploreButtonClick()}>
            <span className="btn-label">EXIT</span>
          </button>
      </div>
    </>
  )
}